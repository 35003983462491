const sessionVariables = {
    USER: 'smartboard-app-user',
};
/** @param {SessionUser} user - Id of logged in user */
export function setSessionUser({ id, email, token, subscriptionStatus }) {
    return window.sessionStorage.setItem(sessionVariables.USER, JSON.stringify({ id, email, token, subscriptionStatus }));
}
/** @returns {SessionUser} - Id of logged in user */
export function getSessionUser() {
    return JSON.parse(window.sessionStorage.getItem(sessionVariables.USER));
}
export function deleteSessionUser() {
    window.sessionStorage.removeItem(sessionVariables.USER);
}
/** @param {SessionUser['subscriptionStatus']} subscriptionStatus */
export function updateSessionUserSubscriptionStatus(subscriptionStatus) {
    setSessionUser(Object.assign(Object.assign({}, getSessionUser()), { subscriptionStatus }));
}
