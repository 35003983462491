if (!window.perf) {
    window.perf = {
        events: [],
        startTime: performance.now(),
        resetTimer: () => {
            window.perf.events = [];
            window.perf.startTime = performance.now();
            console.log('%c Performance Timer Reset', 'color: #FF4081; font-weight: bold;');
        },
        _addEvent: (name, type, data = null) => {
            const now = performance.now();
            const last = window.perf.events[window.perf.events.length - 1];
            const delta = last ? now - last.timestamp : 0;
            const totalTime = now - window.perf.startTime;
            const colors = {
                Info: '#2196F3', // Blue
                Success: '#4CAF50', // Green
                Error: '#F44336', // Red
            };
            const icons = {
                Info: 'ℹ️',
                Success: '✅',
                Error: '❌',
            };
            window.perf.events.push({
                name,
                type,
                timestamp: now,
                delta,
                totalTime,
                data,
            });
            const dataString = data ? `| ${JSON.stringify(data)}` : '';
            console.log(`%c ${icons[type]} ${type} | ${name} | +${delta.toFixed(2)}ms (${totalTime.toFixed(2)}ms) ${dataString}`, `color: ${colors[type]}; font-weight: bold;`);
        },
        Info: (name, data = null) => window.perf._addEvent(name, 'Info', data),
        Success: (name, data = null) => window.perf._addEvent(name, 'Success', data),
        Error: (name, data = null) => window.perf._addEvent(name, 'Error', data),
    };
    // Reset timer on user interactions
    const userEvents = ['click', 'keydown', 'mousedown', 'touchstart'];
    userEvents.forEach((eventName) => {
        window.addEventListener(eventName, () => {
            const lastEvent = window.perf.events[window.perf.events.length - 1];
            if (!lastEvent || performance.now() - lastEvent.timestamp > 1000) {
                window.perf.resetTimer();
            }
        });
    });
}
