import * as fabric from 'fabric'; // v6
/**
 * @typedef {Object} SFabricSettings
 * @property {Object<string, HTMLImageElement>} SVGCache
 */
// /**
//  * @typedef {import('fabric').fabric & SFabricSettings} SFabric
//  */
import { createCustomControls } from './controls-setup.js';
// /**
//  * @param {fabric} fabric
//  * @returns {SFabric}
//  */
export function wrapFabric(fabric) {
    const sfabric = Object.assign(Object.assign({}, fabric), { SVGCache: {} });
    window.fabric = sfabric;
    // createCustomControls(fabric.Textbox.prototype.controls)
    // createCustomControls(fabric.Object.prototype.controls)
    createCustomControls();
    // @ts-ignore
    return sfabric;
}
