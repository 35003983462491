import { html, css } from 'lit';
import RouterMixin, { go } from './shared/router/router-mixin.js';
import './shared/components/app-header.js';
import * as fabric from 'fabric'; // v6
import { wrapFabric } from './smartboard/wrapFabric.js';
import './performance.js';
import { routes } from './shared/router/routes.js';
wrapFabric(fabric);
class AppEnter extends RouterMixin {
    connectedCallback() {
        super.connectedCallback();
        window.perf.Info('AppEnter.connectedCallback');
    }
    render() {
        // return html` ${this.showHeader ? html`<app-header></app-header>` : ''}
        return html `
      ${this.showHeader
            ? html ` <div class="top-bar">
            <h1 class="title" @click=${() => go(routes.DECKS.path, {})}>Smartboard</h1>
            <div class="profile">
              <notifications-section></notifications-section>
              <img
                class="profile-picture"
                @click=${() => go(routes.SETTINGS.path, {})}
                src="https://placehold.co/40x40"
                alt="Profile Picture"
              />
            </div>
          </div>`
            : null}
      <slot></slot>
    `;
    }
}
AppEnter.styles = css `
    :host {
      -webkit-tap-highlight-color: transparent;
    }
    .title {
      font-family: 'Roboto', sans-serif;
      cursor: pointer;
    }
    .top-bar {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .profile {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }
    .profile-picture {
      cursor: pointer;
    }
  `;
customElements.define('app-start', AppEnter);
export default AppEnter;
